import axios from "axios";
import { keys } from "@/lib/utils/keys";

export const fetchRouteInformation = async ({ pathname }) => {
  try {
    const axiosRes = await axios.get(keys.general.graphqlUrl, {
      paramsSerializer: { indexes: null },
      params: {
        query: `query {
          route (url: "${pathname}") {
            id sku type level relative_url redirectCode display_mode has_children component_id is_threebo_view is_type_service_part
            additional { id, label, component_id }
            breadcrumbs { id link label component_id }
          }
        }`,
      },
    });
    return axiosRes.data;
  } catch (error) {
    return { data: [], errors: error };
  }
};
