"use client";

import Cookies from "js-cookie";
import dynamic from "next/dynamic";
import { useEffect, useState } from "react";
import { viewItemListAnalytics } from "@/lib/gtm";
import { getRecentlyViewedProducts } from "@/lib/services/home";
import { createItemListPayload } from "@/lib/utils/helpers/plp";
import {
  formatProductGA4,
  handleToastError,
  mutateProductInfo,
} from "@/lib/utils/functions";

const RecentViewedList = dynamic(() => import("./RecentViewedList"));

const Recent = ({
  headings,
  cookieKeys,
  storageKeys,
  bestSellerImg = "",
  recommendedImg = "",
}) => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const sendGoogleAnalytics = (items) => {
      // View cart GA4 for recently viewed
      const list = formatProductGA4(
        items
          .map((p) => mutateProductInfo(p))
          .map((el) => ({ ...el, quantity: el.extraVariable.qty })),
      );

      viewItemListAnalytics(
        createItemListPayload(list, "Recently Viewed", true),
      );
    };

    const fetchRecentProducts = async () => {
      let skuList = [];
      try {
        const jsonStr = localStorage.getItem(storageKeys.recently_viewed);
        skuList = jsonStr ? JSON.parse(jsonStr) : [];
      } catch (err) {
        console.error("Error fetching recent product:", err);
      }

      if (skuList.length > 0) {
        const sParams = new URLSearchParams(window.location.search);
        let coupon = sParams.get("auto_apply_coupon") || null;
        if (!coupon) {
          coupon = Cookies.get(cookieKeys.specialCoupon);
        }

        try {
          const { products } = await getRecentlyViewedProducts(skuList, coupon);
          sendGoogleAnalytics(products);
          setProducts(products);
        } catch (err) {
          handleToastError(err.message);
        }
      }
    };
    fetchRecentProducts();
  }, []);

  if (products && !products?.length) return null;
  return (
    <RecentViewedList
      products={products}
      bestSellerImg={bestSellerImg}
      recommendedImg={recommendedImg}
      title={headings?.recentlyview_product_title}
      description={headings?.recentlyview_product_desc}
    />
  );
};

export default Recent;
