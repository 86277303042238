"use client";

import dynamic from "next/dynamic";
import FindCarParts from "./findCarParts";
import { useForm } from "react-hook-form";
import { Fragment, useState } from "react";
import { useAppDispatch } from "@/lib/store";
import { registration_lookup } from "@/lib/gtm";
import { yupResolver } from "@hookform/resolvers/yup";
import { checkVehicleSchema } from "@/lib/utils/schemas";
import { updateVehicle } from "@/lib/store/reducers/vehicle";
import Button from "@/components/common/shared/Button/button";
import { getVehicleInfo, handleVehicleResponse } from "@/lib/services/api";
import {
  endCommonLoading,
  startCommonLoading,
} from "@/lib/store/reducers/uiState";
import {
  MVLPayloadToKey,
  handleToastError,
  MVLKeyToQueryString,
} from "@/lib/utils/functions";

const CarModelBox = dynamic(() => import("./CarModelBox"), { ssr: false });

const VehicleForm = ({ t }) => {
  const dispatch = useAppDispatch();
  const [byModel, setByModel] = useState(false);
  const { register, handleSubmit, reset } = useForm({
    resolver: yupResolver(checkVehicleSchema(() => {})),
    defaultValues: {
      reg: "",
      make: "",
      model: "",
      type: "",
      year: "",
      engine: "",
      body: "",
      kw_hp: "",
    },
  });

  const findVehicle = async (data) => {
    const regNo = data?.reg?.replace(/\s/g, "");
    if (!byModel && !regNo) {
      handleToastError(t("messages.error.required_vehicle_registration"));
      return;
    }

    dispatch(startCommonLoading());
    let vRes = null;
    try {
      if (regNo) {
        vRes = await getVehicleInfo(`vrm=${regNo}`, true);
      } else {
        const queryString = MVLKeyToQueryString(
          MVLPayloadToKey({
            make: data?.make,
            type: data?.type,
            year: data?.year,
            body: data?.body,
            kw_hp: data?.kw_hp,
            model: data?.model,
            engine: data?.engine,
          }),
        );
        vRes = await getVehicleInfo(queryString, true);
      }

      const vData = handleVehicleResponse(t, vRes, handleToastError);
      if (!vData) return;
      dispatch(updateVehicle(vData));
      reset();

      let gtagData = {
        model: vData?.vehicle?.model,
        manufacturer: vData?.vehicle?.make,
      };

      if (regNo) {
        gtagData.engine = vData?.vehicle?.engineSize;
        gtagData.years =
          vData?.vehicle?.dateFirstRegistered?.match(/^(\d{4})/)[0];
      } else {
        if (vData?.vehicle?.engineSize) {
          gtagData.engine = vData?.vehicle?.engineSize;
        }
        gtagData.years = vData?.vehicle?.modelVersion?.match(/\d{4}/)[0];
      }
      registration_lookup(gtagData);
      dispatch(endCommonLoading());
    } catch (err) {
      handleToastError(err.message);
    } finally {
      dispatch(endCommonLoading());
    }
  };

  return (
    <Fragment>
      <div className="btnBox">
        <Button
          icon="edit"
          iconPosition="left"
          onClick={() => setByModel(false)}
          className={byModel ? "" : "active"}
          theme={`${byModel ? "grey-secondary-btn" : "white-secondary-btn"}`}
        >
          {t("vehicle.byRegistration")}
        </Button>
        <Button
          icon="car"
          iconPosition="left"
          onClick={() => setByModel(true)}
          className={byModel ? "active" : ""}
          theme={`${!byModel ? "grey-secondary-btn" : "white-secondary-btn"}`}
        >
          {t("vehicle.byModel")}
        </Button>
      </div>
      {byModel ? (
        <CarModelBox t={t} findVehicles={findVehicle} />
      ) : (
        <FindCarParts
          t={t}
          reset={reset}
          register={register}
          findVehicles={findVehicle}
          handleSubmit={handleSubmit}
        />
      )}
    </Fragment>
  );
};

export default VehicleForm;
