"use client";

import { routes } from "@/lib/utils/routes";
import { useAppSelector } from "@/lib/store";
import { useTranslation } from "@/i18n/client";
import styles from "@/assets/css/pages/font.module.css";
import Button from "@/components/common/shared/Button/button";
import CategoryCard from "@/components/common/card/CategoryCard";
const PopularCategoryList = ({ categories = [], headings = {} }) => {
  const { t } = useTranslation("home");
  const { storeConfig } = useAppSelector((state) => state.globalAssets);

  return (
    <section className="w-12/12 pb-6 sm:pb-80 bg-tertiary-white overflow-hidden z-[1] relative xs:min-h-max lg:min-h-[489px] min-h-[298px] categories-section">
      <div className="container">
        <div className="row">
          <div className="col w-12/12">
            <div className="flex justify-between xs:mb-8 mb-4 items-baseline xs:items-end">
              <div className="flex flex-col">
                <h2 className={`${styles.homeHeading} xs:mb-12`}>
                  {storeConfig?.popular_category_title ||
                    headings?.popular_category_title}
                </h2>
                <p className="sub-heading">
                  {storeConfig?.popular_category_desc ||
                    headings?.popular_category_desc}
                </p>
              </div>
              <div className="flex items-center">
                <Button
                  type="link"
                  icon="arrow"
                  href={routes.parts}
                  className="font-semibold"
                  theme="blue-bordered-btn-arrow"
                >
                  {t("btns.view_all_btn")}
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col w-12/12">
            <div className="categories-box">
              {categories.map((item) => (
                <CategoryCard
                  key={item.id}
                  heading={item.name}
                  href={item.url_path}
                  categoryid={item.id}
                  description={item.description}
                  image={item?.image ? `${item.image}?width=152` : item.image}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PopularCategoryList;
