import { keys } from "../keys";
import { constants } from "../constants";
import { handleErrorLog } from "../error_logs";
import { fetchRouteInformation } from "@/lib/services/routeResolve";

export const sortProducts = (products = []) => {
  if (!products || !Array.isArray(products?.items)) {
    return [];
  }

  // Create a copy of the array and sort it
  const sortedProducts = [...products.items].sort((a, b) => {
    if (a.list_sort === null && b.list_sort !== null) {
      return 1;
    }
    if (a.list_sort !== null && b.list_sort === null) {
      return -1;
    }
    return 0;
  });

  return sortedProducts;
};

export const isInvalidRouteForResolve = (pathname) => {
  if (
    [...constants.image_extensions, ...constants.file_extensions].some((str) =>
      pathname.endsWith(str),
    )
  ) {
    return true;
  }
  return constants.next_routes.some((str) => pathname.includes(str));
};

export const getFilteredItems = (vehicleInfo = {}, subChildArr = []) => {
  let filteredArr = subChildArr;
  const components = vehicleInfo?.vehicle?.components || [];
  if (components.length === 0) {
    filteredArr?.filter((childItem) => {
      const hasComponent = components?.some(
        ({ id }) => id == childItem.componentId,
      );
      return !childItem?.hasComponentId || !vehicleInfo || hasComponent;
    });
  }
  return filteredArr;
};

export const getUrlInfo = async ({ url, slug = "" }) => {
  let urlpath = url.join("/");
  if (keys.general.addExtension) {
    urlpath = urlpath.concat(".html");
  }

  const pathname = `/${decodeURIComponent(urlpath)}`;
  const { data, errors = null } = await fetchRouteInformation({ pathname });
  if (errors || data?.route === null) {
    handleErrorLog({
      error: errors,
      is_warning: !errors,
      additional_info: { slug, pathname },
      msg: `${!errors ? "No response" : "Error"} while resolving URL`,
    });
  }

  const { type, breadcrumbs, additional = null, ...rest } = data?.route || {};
  return {
    type,
    rest,
    additional,
    breadcrumbs,
    lastSegment: url[url.length - 1],
    is3BO: rest?.is_threebo_view === 1,
    isCarParts: rest?.is_type_service_part === 1,
    hasChildren: rest?.has_children && parseInt(rest?.has_children) > 0,
  };
};

export { handleErrorLog };
