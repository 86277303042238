import axios from "axios";
import { keys } from "../utils/keys";
import { getYouMayLikeProducts } from "./cart";
import { productListModel } from "./fragments";
import { handleGraphqlResponse } from "../utils/functions";

const baseRequest = async (query, variables = {}) => {
  const url =
    typeof window === "undefined"
      ? keys.general.graphqlUrl
      : keys.general.backendGraphqlUrl;
  try {
    const queryString = JSON.stringify({ query, variables });
    const { data } = await axios.get(url, {
      params: { query, variables: queryString },
      paramsSerializer: {
        indexes: null, // Ensures proper serialization
      },
    });
    return [handleGraphqlResponse(data), null];
  } catch (err) {
    return [
      null,
      {
        message: err.message,
      },
    ];
  }
};

// get seasonal products for homepage
export const getSeasonalProducts = ({ specialCoupon }) => {
  const productModal = productListModel();
  return baseRequest(`{
    seasonal_products: products (
      filter: {seasonal_product: { eq: 1 }, price: { from: 0.01 } }
      sort: { seasonal_product_sort_order: ASC }
      pageSize: 6
      specialCoupon: "${specialCoupon || ""}"
    ) {
      total_count
      items { ...Products list_sort: seasonal_product_sort_order }
    }
  } ${productModal}`);
};

// get featured products for homepage
export const getFeaturedProducts = ({ specialCoupon }) => {
  const productModal = productListModel();
  return baseRequest(`{
    featured_products: products (
      filter: { featured_product: { eq: 1 }, price: { from: 0.01 } }
      sort: { featured_product_sort_order: ASC }
      pageSize: 8
      specialCoupon: "${specialCoupon || ""}"
    ) {
      total_count
      items { ...Products list_sort: featured_product_sort_order }
    }
  } ${productModal}`);
};

export const getPopularCategories = (withoutStoreConfig = false) => {
  return baseRequest(`{
    getPopularCategories(currentStoreId: 1${
      withoutStoreConfig ? "" : ", pageSize: 6"
    }) {
      pageSize
      category {
        id name url_path image description meta_title meta_keyword meta_description
      }
    }
  }`);
};

export const getRecentlyViewedProducts = async (skuList, coupon) => {
  const itemSchema = productListModel("recent");
  const variables = JSON.stringify({
    productfilters: {
      sku: { in: skuList },
      price: { from: 0.01 },
    },
  });

  try {
    const axiosRes = await axios.get(keys.general.backendGraphqlUrl, {
      paramsSerializer: { indexes: null },
      params: {
        variables,
        query: `query ProductFilter ($productfilters: ProductAttributeFilterInput) {
          products (filter: $productfilters specialCoupon: "${coupon || ""}") {
            total_count
            items { ...Products }
          }
        } ${itemSchema}`,
      },
    });

    let products = [];
    const productRes = axiosRes.data?.data?.products || null;
    if (productRes) {
      products = skuList
        ?.map((sku) => productRes?.items?.find((p) => p.sku == sku))
        .filter(Boolean);

      const remainingSlots = 4 - products.length;
      if (remainingSlots > 0) {
        const cartProductsRes = await getYouMayLikeProducts({
          itemSchema,
          order: "ASC",
          specialCoupon: coupon,
          pageSize: remainingSlots, // Adjust the pageSize based on remaining products needed
        });

        const cartProducts = cartProductsRes?.data?.products || null;
        if (cartProducts?.items && Array.isArray(cartProducts?.items)) {
          products = [...products, ...cartProducts.items];
        }
      }
    }
    return { products };
  } catch (error) {
    console.error(error);
    return { products: [] };
  }
};
